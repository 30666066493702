import { defineStore } from "pinia";
import Api from "../services/Api";
import { userStore } from "@/stores/user";

export const claimStore = defineStore("claim", {
  state: () => {
    return {
      claim: "",
      claims: [],
      memberClaims: [],
    };
  },
  getters: {
    pendingClaims: (state) => {
      return state.claims.filter((claim) => claim.status == "pending");
    },
    approvedClaims: (state) => {
      return state.claims.filter((claim) => claim.status == "approved");
    },
    deniedClaims: (state) => {
      return state.claims.filter((claim) => claim.status == "denied");
    },
  },
  actions: {
    getClaims() {
      return Api.getClaims()
        .then((res) => {
          this.claims = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    getSingleMeberClaims() {
      return Api.getMemberClaims(userStore().user.id)
        .then((res) => {
          this.memberClaims = res.data.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    createClaim(data) {
      return Api.createClaim(data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    editClaim(id, data) {
      return Api.editClaim(id, data)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteClaim(id) {
      return Api.deleteClaim(id)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
});
