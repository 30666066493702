<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">approved claims</div>
      </v-row>
      <v-row class="">
        <div class="">
          View all the approved claims and deny them if need be
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-data-table
        class="text-capitalize elevation-10"
        :search="search"
        :items="claims"
        :headers="headers"
        item-key="id"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | moment("ddd, MMMM Do YYYY") }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
          {{ item.user.name }}
        </template>
        <template v-slot:[`item.claim_type`]="{ item }">
          {{ item.claim_type }}
        </template>
        <template v-slot:[`item.receipt`]="{}">
          <v-btn small rounded color="primary" @click="receiptDialog = true">
            <v-icon left>mdi-file</v-icon> view</v-btn
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            outlined
            color="primary"
            rounded
            small
            @click="(approveDialog = true), (claimToEdit = item)"
          >
            deny
          </v-btn>
        </template>
      </v-data-table>

      <v-dialog v-model="receiptDialog">
        <pdf-viewer />
      </v-dialog>

      <v-dialog v-model="approveDialog" width="auto">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">deny claim</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="approveForm" ref="approveForm">
              <v-textarea
                label="Reason"
                :rules="[rules.required]"
                outlined
                dense
                v-model="reason"
              ></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="approveDialog = false">cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="!approveForm"
              :loading="approveLoader"
              @click="claimUpdate()"
              >submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-center text-capitalize">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import PdfViewer from "@/components/PdfViewer.vue";
import { claimStore } from "@/stores/claims.js";
import { mapState, mapActions } from "pinia";

export default {
  components: { PdfViewer },
  data() {
    return {
      receiptDialog: false,
      approveDialog: false,
      reason: "",
      search: "",
      headers: [
        {
          text: "date",
          value: "created_at",
          class: "text-uppercase black--text",
        },
        {
          text: "member",
          value: "user",
          class: "text-uppercase black--text",
        },
        {
          text: "claim type",
          value: "claim_type",
          class: "text-uppercase black--text",
        },
        {
          text: "amount",
          value: "amount",
          class: "text-uppercase black--text",
        },
        {
          text: "receipt",
          value: "receipt",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      rules: {
        required: (v) => !!v || "Required",
      },
      approveForm: false,
      approveLoader: false,
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      claimToEdit: null,
    };
  },
  computed: {
    ...mapState(claimStore, {
      claims: "approvedClaims",
    }),
  },
  methods: {
    ...mapActions(claimStore, ["getClaims", "editClaim"]),
    claimUpdate() {
      this.approveLoader = true;

      this.editClaim(this.claimToEdit.id, {
        amount: this.claimToEdit.amount,
        status: "denied",
        reason: this.reason,
        claim_type: this.claimToEdit.claim_type,
        user_id: this.claimToEdit.user_id,
        file_type: "claim",
        receipt_id: this.claimToEdit.receipt_id.toString(),
      })
        .then(() => {
          this.$refs.approveForm.reset();
          this.showSnackBar(true, "claim denied succesfully");
          this.getClaims();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.approveLoader = false;
          this.approveDialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getClaims();
  },
};
</script>

<style></style>
